import React from 'react';
import ReactDOM from 'react-dom';
import * as ReactDOMServer from 'react-dom/server';
import axios from 'axios';
import { Formio } from '@formio/react';
import Constants from '../../constants';
import Session from '../../session';
import '../../index.css'; 

const uuidv4 = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );

const GetWorkspace = () => {
  return document.getElementById('registration-forms-workspace');
};

export const GetFormDocHeaders = (useCloudBootstrap) => (
  <>
    <link
      rel='stylesheet'
      href='https://cdn.form.io/formiojs/formio.full.min.css'
    />
    <link
      href='https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap'
      rel='stylesheet'
    />
    <link
      rel='stylesheet'
      href={
        useCloudBootstrap
          ? 'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css'
          : '/assets/bootstrap.css'
      }
    />
    <style>
      {`.field-required:after,
        .tab-error::after {
          display: none;
        }`}
    </style>
    <script src='https://cdn.form.io/formiojs/formio.full.min.js' />
  </>
);

export const GetFormDocTemplate = (
  includeHeaders,
  useCloudBootstrap,
  formioRef
) => (
  <div className='form-view-cont'>
    <div className='form-view-doc'>
      {includeHeaders && GetFormDocHeaders(useCloudBootstrap)}
      {formioRef ? (
        <div id='formio-result' ref={formioRef} />
      ) : (
        <div id='formio-result' />
      )}
    </div>
  </div>
);

export const CreateIFrameInstance = (delegate) => {
  const ws = GetWorkspace();
  const id = uuidv4();

  const html = `<html>
        <head>
            ${ReactDOMServer.renderToString(GetFormDocHeaders(true))}
            <script>
                window._Formio = Formio;
            </script>
        </head>
        <body></body>
    </html>`;

  var frame = document.createElement('iframe');
  frame.id = id;
  frame.width = '100%';
  frame.height = '400px';
  frame.style.display = 'none';
  ws.appendChild(frame);
  frame.contentDocument.write(html);

  frame.contentWindow.onload = (e) => {
    delegate(e.currentTarget).then(() => {
      frame.remove();
    });
  };

  frame.contentDocument.close();
};

const LoadFormData = (
  contElement,
  coverKey,
  formPath,
  formData,
  submissionData,
  key,
  _Formio,
  formId = null
) =>
  new Promise((resolve, reject) => {
  let f;
  try {
    f = JSON.parse(formData);
  } catch (error) {
    console.error("Invalid JSON in formData:", error);
    throw new Error("Invalid JSON in formData"); 
  }

  if (!f || !f.components) {
    console.error("Form data is missing 'components':", f);
    throw new Error("Form data is missing 'components'");  
  }
  (_Formio ?? Formio)
    .createForm(
      contElement,
      {},
      {
        renderMode: 'flat',
        readOnly: true,
        hide: {
          submit: true,
        },
      }
    )
    .then((form) => {
      form.on('initialized', function () {
        resolve(key);
      });
      form.setForm({ components: f.components }).then(() => {
        form.ready.then(() => {
          const s = {
            data: {
              ...form.submission.data,
              ...submissionData,
            },
          };
          form.setSubmission(s);
        });
      });
    })
   .catch((error) => {
      console.error("Error creating form:", error);
      reject(error);
    });   
});

export default LoadFormData;
