import React, { useState, useEffect, useReducer, useContext } from "react";
import ReactDOM from "react-dom";
import Checkbox from "@material-ui/core/Checkbox";
import "../PatientForm/FormStatus.scss";
import "./RegistrationForms.scss";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import axios from "axios";
import moment from "moment/min/moment-with-locales";
import FormView from "./FormView";
import PageviewIcon from "@material-ui/icons/PageviewOutlined";
import PrintIcon from "@material-ui/icons/Print";
import LoadFormData, { CreateIFrameInstance } from "./FormLoader";
import RegistrationFormsFilter from "./RegistrationFormsFilter";
import { useResponsiveUtil, useUIContext } from "../../ContextLib/contextHooks";
import Button from "@material-ui/core/Button";
import { MdLowPriority } from "react-icons/md";
import { useTour } from "@reactour/tour";
import TourSteps from "../../Store/TourSteps";
import { TourServiceContext } from "../../Store/TourServiceProvider";
import Constants from "../../constants";
import Session from "../../session";
import { Severity } from "../../ContextLib/CoreConsumer/Components/SnackbarMessage";
import RegistrationFormPagination from "./RegistrationFormPagination";
import "./RegistrationFormPagination.scss";
import Pagination from "@mui/material/Pagination";

const reducer = (state, action) => {
  switch (action.type) {
    case "init":
      return action.value;
    case "update":
      let sel = state.find((x) => x.key == action.value.key);
      if (sel) {
        sel.selected = action.value.selected;
      }
      return state;
    default:
      return state;
  }
};

const RegistrationFormView = ({ coverKey }) => {
  const { setCurrentTab, setTourNext } = useContext(TourServiceContext);

  const {
    isOpen,
    currentStep,
    setIsOpen,
    setCurrentStep,
    setSteps,
    setDisabledActions,
    stepsLength,
    disabledActions,
  } = useTour();
  let componentId = "registration-form-view";
  const ui = useUIContext(componentId);

  const [selection, dispatch] = useReducer(reducer, []);
  const forceUpdate = React.useReducer(() => ({}))[1];

  const _sdt = sessionStorage.getItem("rf-sdt");
  const _edt = sessionStorage.getItem("rf-edt");
  const sdt = moment(_sdt ? JSON.parse(_sdt) : new Date()).format("MM/DD/YYYY");
  const edt = (
    _edt ? moment(JSON.parse(_edt)) : moment(new Date()).add(7, "days")
  ).format("MM/DD/YYYY");
  const _filteredlocKey = sessionStorage.getItem("rf-locKey");

  const [startDate, setStartDate] = useState(sdt);
  const [endDate, setEndDate] = useState(edt);
  const [searchType, setSearchType] = useState(0);
  const [selectedData, setSelectedData] = useState(null);
  const [patientsData, setPatientsData] = useState([]);
  const [view, setView] = useState("main");
  const [dateGridId, setDateGridId] = useState("responsedate");
  const [dateGridHeader, setDateGridHeader] = useState("Response Date");
  const [searchValue, setSearchValue] = useState("");
  const [searchLockey, setSearchLocKey] = useState((_filteredlocKey == null) || isNaN(_filteredlocKey) || (_filteredlocKey == 0) ? -1 : _filteredlocKey);

  const loadData = async (startDate, endDate, searchLockey) => {
    ui.ShowOverlay();

    let _formsData = [];
    let _patientsData = [];

    let token = Session.getItem(Constants.jwtKey);
    //console.log(token.jwt);

    const config = {
      headers: {
        Authorization: `Bearer ${token.jwt}`,
        "x-frm-adm": "bb2910fb-1a14-4df9-9483-e25e40387163",
      },
    };
    const _axios = axios.create();
    const url = `${process.env.REACT_APP_PATIENT_FORMS}/getForms?coverKey=${coverKey}`;
    const res = await _axios.get(url, config);

    if (res?.data.PatientDataList) {
      _formsData = res.data.PatientDataList.map((item, idx) => {
        return {
          formid: item.Id,
          name: item.Name,
          pathName: item.PathName,
        };
      });
    }

    let urldata = "";
    /*     if (searchType === 1)
        {
           urldata = `${process.env.REACT_APP_PATIENT_FORMS}/getAllDataByDateCreated?start=${startDate}&end=${endDate}&coverKey=${coverKey}`;
           setDateGridHeader("Response Date")
        }
        else
        { */
    //const startDate = moment(new Date()).format("MM/DD/YYYY");
    //urldata = `http://localhost:63942/api/FormsAdmin/getDataByAppointmentDate?coverKey=${coverKey}&pageNumber=${currentPage}&pageSize=${itemsPerPage}`;
    urldata = `${process.env.REACT_APP_PATIENT_FORMS}/getDataByAppointmentDate?coverKey=${coverKey}&pageNumber=${currentPage}&pageSize=${itemsPerPage}`;
    setDateGridHeader("Appointment Date");
    /*   } */
    const data = {
      StartDate: moment(startDate).format("YYYY-MM-DD"),
      EndDate: moment(endDate).format("YYYY-MM-DD"),
      SearchType: searchType,
      SearchValue: searchValue,
      LocKey: searchLockey
    };
    try {
      const resdata = await _axios.post(urldata, data, config);
      // console.log(JSON.stringify(resdata?.data.PatientDataList));
      // console.log("total Page: " + resdata?.data.TotalPages);
      if (resdata?.data.PatientDataList) {
        _patientsData = resdata.data.PatientDataList.map((item, idx) => {
          let f = _formsData.find((x) => x.formid == item.FormId);
          let formPath = f?.pathName ?? "";

          var appointmentdate = null;
          if (item.AppointmentDate !== null)
            appointmentdate = new Date(item.AppointmentDate);

          return {
            id: idx + 1,
            firstname: item.FirstName,
            lastname: item.LastName,
            middlename: item.MiddleName,
            telephone: item.Telephone,
            formname: item.Name,
            appointmentdate:
              appointmentdate === null
                ? ""
                : moment(appointmentdate).format("YYYY/MM/DD"),
            datecreated: moment(item.DateCreated).format("MM/DD/YYYY hh:mm"),
            email: item.Email,
            formid: item.FormId,
            data: item.Data,
            formData: item.FormData,
            formPath: formPath,
            patientid: item.Id,
            key: `${formPath}_${item.Id}`,
            lockey: item.LocKey
          };
        });

        const _selection = _patientsData.map((x) => {
          return {
            key: x.key,
            selected: false,
          };
        });

        dispatch({ type: "init", value: _selection });
      }
      //Page the data
      setPatientsData(_patientsData);
      setTotalPages(resdata.data.TotalPages);
      setLoading(false);
    } catch (ex) {
      var error = ex;
    }

    ui.HideOverlay();
  };

  const print = () => {
    try {
      ui.ShowOverlay();

      const s = selection.filter((x) => x.selected);
      if (s.length == 0) {
        ui.MessageBox("No item selected", "Print Selected Item(s)");
        ui.HideOverlay();
        return;
      }

      CreateIFrameInstance(
        (fwindow) =>
          new Promise((resolve, reject) => {
            var contents = (
              <div className="form-view-cont">
                {s.map((x) => (
                  <div
                    className="form-view-doc"
                    style={{ pageBreakAfter: "always" }}
                  >
                    <div id={`formio-result-${x.key}`}></div>
                  </div>
                ))}
              </div>
            );
            ReactDOM.render(contents, fwindow.document.body);

            new Promise((resolve) => {
              let i = 0;
              s.forEach((x) => {
                const formPath = x.key.split("_")[0];
                const pd = patientsData.find((y) => y.key == x.key) ?? {};
                const submissionData = JSON.parse(pd.data);
                const formData = pd.formData;
                let cont = fwindow.document.getElementById(
                  `formio-result-${x.key}`
                );

                LoadFormData(
                  cont,
                  coverKey,
                  formPath,
                  formData,
                  submissionData,
                  x.key,
                  fwindow._Formio,
                  formPath
                    ? null
                    : patientsData.find((i) => i.key === x.key)?.formid
                ).then(() => {
                  i++;
                  if (i >= s.length) {
                    resolve(true);
                  }
                });
              });
            }).then(() => {
              setTimeout(() => {
                ui.HideOverlay();
                fwindow.focus();
                fwindow.print();
                resolve(true);
              }, 2000);
            });
          })
      );
    } catch (e) {
      ui.ShowSnackbar(e.message, Severity.error);
      ui.HideOverlay();
    }
  };

  const actions = {
    name: "data",
    header: "Actions",
    defaultWidth: 120,
    type: "string",
    className: "actions-column",
    render: ({ value, data }) => {
      const v = JSON.parse(value);
      const key = `${data.formPath}_${data.patientid}`;
      const found = selection.find((x) => x.key == key)?.selected;

      return (
        <>
          <a
            href="#"
            title="View Patient Data"
            data-tut="reactour__magnifyingglasscompleted"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              setSelectedData({
                key: key,
                formPath: data.formPath,
                formId: data.formid,
                submissionData: v,
                formData: data.formData,
                locKey: data.locKey
              });
              setView("form-view");
            }}
          >
            <PageviewIcon />
          </a>
          <Checkbox
            checked={found}
            onChange={(e) => {
              let v = e.target.checked;
              let sel = selection.find((x) => x.key == key);
              if (sel) {
                v = !sel.selected;
              }
              dispatch({ type: "update", value: { key: key, selected: v } });
              forceUpdate();
            }}
          />
        </>
      );
    },
  };

  const _columns = [
    { name: "formname", header: "Form Name", defaultWidth: 300 },
    {
      name: "firstname",
      header: "First Name",
      defaultWidth: 100,
      defaultFlex: 1,
    },
    { name: "lastname", header: "Last Name", defaultFlex: 1 },
    { name: "appointmentdate", header: "Appointment Date", defaultFlex: 1 },
    { name: "datecreated", header: "Response Date", defaultFlex: 1 },
    { name: "email", header: "Email", defaultFlex: 1 },
    { name: "telephone", header: "Telephone Number", defaultFlex: 1 },
    { name: "lockey", header: "LocKey", defaultFlex: 1 },
  ];

  const bp = useResponsiveUtil();
  const columns = bp.xs.window
    ? [actions, ..._columns]
    : [..._columns, actions];

  const makeSelection = (checked) => {
    let _selection = selection.map((x) => {
      return {
        ...x,
        selected: checked,
      };
    });
    dispatch({ type: "init", value: _selection });
  };

  const gridStyle = {
    position: "relative",
    height: "calc(100vh - 450px)",
    fontSize: "13px",
  };

  useEffect(async () => {
    if (coverKey > 0) loadData(startDate, endDate, searchLockey);
  }, [coverKey, startDate, endDate, searchLockey]);

  useEffect(() => {
    // Set Tours
    setIsOpen(false);
    setSteps(TourSteps.tourStepsCompletedForms);
    setCurrentTab(componentId);
  }, []);

  const onClickSearch = () => {
    setLoading(true);
    setCurrentPage(1);
    setPatientsData([]);
    setTotalPages(0);
    loadData(startDate, endDate, searchLockey);
  };

  const onLocKeyFilter = (locKey) => {
    setLoading(true);
    setCurrentPage(1);
    setPatientsData([]);
    setTotalPages(0);
    loadData(startDate, endDate, locKey);
  };

  //Paging
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50); // Number of items per page
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(0);


  const handlePageChange = (e,p) => {
    setCurrentPage(p);
  };

  useEffect(() => {
    loadData(startDate, endDate);
  }, [currentPage, itemsPerPage]);

  const handleRowsPerPageChange = (value) => {
    setItemsPerPage(value);
    setSelectedRowsPerPage(value);
    setCurrentPage(1);
  };

  //Paging
  return (
    <>
      <div className="form-status">
        {view == "main" ? (
          <>
            <div className="view-header">
              <RegistrationFormsFilter
                _setStartDate={setStartDate}
                _setEndDate={setEndDate}
                _searchType={setSearchType}
                _searchValue={setSearchValue}
                _onclick={onClickSearch}
                _coverKey={coverKey}
                _onLocKeyFilter={onLocKeyFilter}
                _currentLocKey={setSearchLocKey}
              />
            </div>
            <div className="view-body">
              <div className="selection-commands">
                <a href="#" onClick={(e) => makeSelection(true)}>
                  Select All
                </a>
                &nbsp;|&nbsp;
                <a href="#" onClick={(e) => makeSelection(false)}>
                  Unselect All
                </a>
                &nbsp;|&nbsp;
                <Button
                  onClick={print}
                  title="Print Selected Item(s)"
                  data-tut="reactour__print"
                >
                  <PrintIcon />
                  &nbsp;Print
                </Button>
              </div>
              {/* <div className='table-responsive'>
                <ReactDataGrid
                  idProperty='id'
                  columns={columns}
                  pagination={true}
                  dataSource={patientsData}
                  showColumnMenuTool={false}
                  style={gridStyle}
                />
              </div> */}
              <div>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <div className="table-responsive">
                    <ReactDataGrid
                      idProperty="id"
                      columns={columns}
                      dataSource={patientsData}
                      rowGetter={(i) => patientsData[i]}
                      rowsCount={patientsData.length}
                      showColumnMenuTool={false}
                      style={gridStyle}
                    />
                    {patientsData.length > 0 && (
                      <div className="pagination-container">
                        {/* <div className="pagination">
                          <RegistrationFormPagination
                            totalPages={totalPages}
                            handlePageChange={handlePageChange}
                            activePage={currentPage}
                          />
                        </div> */}
                        <div className="rows-per-page">
                          <label>
                            Rows per page:
                            </label>
                            <select
                              value={selectedRowsPerPage}
                              onChange={(e) =>
                                handleRowsPerPageChange(Number(e.target.value))
                              }
                            >
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={30}>30</option>
                              <option value={40}>40</option>
                              <option value={50}>50</option>
                            </select>
                        </div>
                        <Pagination count={totalPages} className="pagination-nav" color="primary" onChange={handlePageChange} > </Pagination>
                      </div>
                    )} 
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <FormView data={selectedData} coverKey={coverKey} setView={setView} />
        )}
      </div>
    </>
  );
};

export default RegistrationFormView;
